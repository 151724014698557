///*****************************************************
//*************                       ******************
//*************        ELEVATE        ******************
//*************                       ******************
//******************************************************/

:root {
    --box-shadow-z1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    --box-shadow-z2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    --box-shadow-z3: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    --box-shadow-z4: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    --box-shadow-z5: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

    --box-shadow-z1-inset: inset 0 -1px 3px rgba(0, 0, 0, 0.12), inset 0 -1px 2px rgba(0, 0, 0, 0.24);
    --box-shadow-z2-inset: inset 0 -3px 6px rgba(0, 0, 0, 0.16), inset 0 -3px 6px rgba(0, 0, 0, 0.23);
    --box-shadow-z3-inset: inset 0 -10px 20px rgba(0, 0, 0, 0.19), inset 0 -6px 6px rgba(0, 0, 0, 0.23);
    --box-shadow-z4-inset: inset 0 -14px 28px rgba(0, 0, 0, 0.25), inset 0 -10px 10px rgba(0, 0, 0, 0.22);
    --box-shadow-z5-inset: inset 0 -19px 38px rgba(0, 0, 0, 0.3), inset 0 -15px 12px rgba(0, 0, 0, 0.22);
}

.elevate-z1 {
    box-shadow: var(--box-shadow-z1);
}

.elevate-z2 {
    box-shadow: var(--box-shadow-z2);
}

.elevate-z3 {
    box-shadow: var(--box-shadow-z3);
}

.elevate-z4 {
    box-shadow: var(--box-shadow-z4);
}

.elevate-z5 {
    box-shadow: var(--box-shadow-z5);
}

.excavate-z1 {
    box-shadow: var(--box-shadow-z1-inset);
}

.excavate-z2 {
    box-shadow: var(--box-shadow-z2-inset);
}

.excavate-z3 {
    box-shadow: var(--box-shadow-z3-inset);
}

.excavate-z4 {
    box-shadow: var(--box-shadow-z4-inset);
}

.excavate-z5 {
    box-shadow: var(--box-shadow-z5-inset);
}

.cdk-drag-placeholder {
    & > * {
        & > .tile {
            background-color: var(--favorites-tile-edition-mode);
            color: var(--favorites-tile-edition-mode);
        }
    }
}

ion-fab-button {
    --box-shadow: var(--box-shadow-z1); // Box shadow of the button
}

div.vertically-center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin: auto;
}

div.vertically-centered-content {
    width: 100%;
    max-height: 100%;
}

.strikethrough {
    text-decoration: line-through;
}

ion-popover {
    --width: fit-content;
}

.itinerary-transport-leg-thermometer-path {
    .duration {
        opacity: 0.87;
        font-weight: 500;
        fill: var(--text-color);
        text-transform: uppercase;
    }

    z-index: 2;
}

ion-item-divider:empty {
    min-height: 8px; // was 32px;
}

hr {
    height: 1px;
    width: 100%;
    background-color: var(--ion-item-separator);
}

// setting up the default SVG line
app-itinerary-leg-transport svg line.default-color {
    stroke: var(--leg-default-line-color);
}

.icon-track {
    font-size: 0.7rem;
    top: -0.04rem;
    line-height: 0.75rem;
    position: absolute;
    text-align: left;
    left: -0.2rem;
    transform-origin: 0.9rem 0.5rem;
    // The minimal char size on android is ~8 pixels,
    // to display smaller chars we scale the entire text component
    transform: scale(0.5);
    width: 100%;
}

.icon-track-number {
    bottom: 0.3rem;
    font-size: 1rem;
    left: 0;
    line-height: 0.95;
    position: absolute;
    text-align: center;
    width: 100%;
}

.gps-localization-alert {
    .alert-message {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

app-line-icon {
    display: flex;
    position: relative;

    .line-badge {
        border-radius: var(--line-icon-radius);
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--line-icon-height);
        min-width: var(--line-icon-width);
    }
}

.primary-background {
    --background: var(--ion-color-primary);
    --color: var(--ion-color-contrast);
}

.thermo-icon-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    ion-icon {
        width: var(--bullet-thermo-size);
        height: var(--bullet-thermo-size);
        z-index: 2;
    }

    .line-path {
        --default-value: calc(60% + var(--line-path-bottom, 0px));
        position: absolute;
        height: var(--line-path-height, var(--default-value));
        width: var(--line-path-width);
        bottom: var(--line-path-bottom, 0px);
        z-index: 1;
        background-color: var(--ion-color-tertiary);
    }
}

ion-list.list-inset {
    border: solid 2px var(--ion-color-primary);
}
