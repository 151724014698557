/**
Make modals looks like alerts
 */
.ticket-purchase .modal-wrapper {
    height: 70%;
    margin-left: 6.4%;
    margin-right: 6.4%;
    border-radius: 5px;
}

.header-md:after {
    background-image: none;
}

ion-toolbar.in-toolbar ion-buttons,
ion-toolbar.in-toolbar ion-title {
    transition: none;
}

ion-tab-bar {
    //@media screen and (max-width: 320px) {
    //    height: 5rem;
    //}
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

.custom-alert {
    .alert-wrapper {
        text-align: center;

        .alert-message {
            color: var(--text-color);
        }

        .alert-text {
            text-align: left;
        }

        .icon-container {
            display: flex;
            justify-content: center;
            padding-bottom: 0.5rem;
            font-size: 1.3rem;
        }
    }

    &.disruption-alert {
        h6 {
            margin-top: 0;
        }

        .icon-container {
            font-size: 2rem;
            color: red;
        }
    }
}

ion-segment {
    ion-segment-button {
        ion-label {
            font-weight: bold;
        }
    }
}

/*
Offline Info Message
*/
.offline-message {
    position: relative;
    text-align: center;
    top: 50%;
    transform: translate(0, -50%);
    width: 90%;
    margin-left: 5%;
}

ion-spinner {
    --spinner-color: var(--text-color);
}

ion-accordion {
    // Forced, to be correct color on android
    .ion-accordion-toggle-icon,
    ion-icon {
        color: var(--ion-text-color);
    }

    &.accordion-expanding,
    &.accordion-expanded {
        > ion-item[slot='header'] {
            --color: var(--ion-color-primary-contrast);
            --background: var(--ion-color-primary);
        }

        // Forced, to be correct color on android
        .ion-accordion-toggle-icon,
        ion-icon {
            color: var(--ion-color-primary-contrast);
        }
    }

    [slot='content'] {
        // this is for subaccordions
        // // sub accordion is used in tickets list travys : libs/boldor/travys/src/lib/feature-ticket/containers/ticket-list/*
        &.accordion-expanding,
        &.accordion-expanded {
            > ion-item[slot='header'] {
                --color: var(--ion-color-primary-contrast);
                --background: rgba(var(--ion-color-primary-rgb), 0.3);
            }
        }

        // sub accordion is used in tickets list travys : libs/boldor/travys/src/lib/feature-ticket/containers/ticket-list/*
        &:not(:last-child):not(.sub-accordion) {
            border-bottom: 1px solid var(--ion-item-separator);
        }
    }
}

ion-footer {
    border-top: 0.1rem solid var(--line-separator);
    background-color: var(--footer-background);
    padding: var(--ion-padding, 1rem) 0;
}
