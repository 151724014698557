:root {
    // We have to declare a global class instead of declaring it in our component because the shadow dom f*ck us up

    // // datePickerBar Arrival or Departure ion-select
    // .item.sc-ion-label-ios-h,
    // .item .sc-ion-label-ios-h {
    //     color: var(--text-color);
    // }

    // .item.sc-ion-label-md-h,
    // .item .sc-ion-label-md-h {
    //     color: var(--text-color);
    // }

    .open {
        ion-label {
            color: #ffffff !important;
        }
    }

    //ion-select-popover {
    //    ion-item {
    //        --border-width: 0;
    //        --inner-border-width: 0;
    //
    //        &:first-child {
    //            border-bottom: 1px solid var(--form-border);
    //        }
    //    }
    //}

    .customer-editor-form .native-input[disabled],
    .select-disabled,
    .datetime-disabled {
        opacity: 1;
    }

    app-frequent-traveler-form,
    .fares ion-item {
        --inner-border-width: 0;
    }
}

h6 {
    font-weight: bold;
}
