// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// NEVER MODIFY THOSE VALUES BY HAND
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Use https://ionicframework.com/docs/theming/color-generator
// Change colors and copy/paste BY BLOCK (like only primary color) the generated CSS

//https://ionicframework.com/docs/theming/dark-mode

:root {
    --color: white;
    --background-color: black;
    --item-background-color: #333333;
    --corner-radius: 20px;
    --stroke-width: 0.8rem;
    --icons-size: 3rem;
    --dot-width: 2.5rem;

    --default-rem-size: 35px;
    font-size: var(--default-rem-size);

    .dark {
        --ion-color-primary: #a2c632;
        --ion-color-primary-rgb: 162, 198, 50;
        --ion-color-primary-contrast: #ffffff;
        --ion-color-primary-contrast-rgb: 255, 255, 255;
        --ion-color-primary-shade: #8fae2c;
        --ion-color-primary-tint: #abcc47;

        --ion-color-secondary: #e6ee9c;
        --ion-color-secondary-rgb: 230, 238, 156;
        --ion-color-secondary-contrast: #000000;
        --ion-color-secondary-contrast-rgb: 0, 0, 0;
        --ion-color-secondary-shade: #cad189;
        --ion-color-secondary-tint: #e9f0a6;

        --ion-color-tertiary: #ffffff;
        --ion-color-tertiary-rgb: 255, 255, 255;
        --ion-color-tertiary-contrast: #000000;
        --ion-color-tertiary-contrast-rgb: 0, 0, 0;
        --ion-color-tertiary-shade: #e0e0e0;
        --ion-color-tertiary-tint: #ffffff;

        --ion-color-success: #2dd36f;
        --ion-color-success-rgb: 45, 211, 111;
        --ion-color-success-contrast: #000000;
        --ion-color-success-contrast-rgb: 0, 0, 0;
        --ion-color-success-shade: #28ba62;
        --ion-color-success-tint: #42d77d;

        --ion-color-warning: #ffc409;
        --ion-color-warning-rgb: 255, 196, 9;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0ac08;
        --ion-color-warning-tint: #ffca22;

        --ion-color-danger: #eb445a;
        --ion-color-danger-rgb: 235, 68, 90;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #cf3c4f;
        --ion-color-danger-tint: #ed576b;

        --ion-color-dark: #222428;
        --ion-color-dark-rgb: 34, 34, 34;
        --ion-color-dark-contrast: #ffffff;
        --ion-color-dark-contrast-rgb: 255, 255, 255;
        --ion-color-dark-shade: #1e2023;
        --ion-color-dark-tint: #383a3e;

        --ion-color-medium: #92949c;
        --ion-color-medium-rgb: 146, 148, 156;
        --ion-color-medium-contrast: #000000;
        --ion-color-medium-contrast-rgb: 0, 0, 0;
        --ion-color-medium-shade: #808289;
        --ion-color-medium-tint: #9d9fa6;

        --ion-color-light: #f4f5f8;
        --ion-color-light-rgb: 244, 245, 248;
        --ion-color-light-contrast: #000000;
        --ion-color-light-contrast-rgb: 0, 0, 0;
        --ion-color-light-shade: #d7d8da;
        --ion-color-light-tint: #f5f6f9;
    }
}
