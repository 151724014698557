:root {
    // Backgrounds
    --default-background: #fff;
    --default-background-rgb: 255, 255, 255;
    --dark-background: #262626;
    --medium-background: #8c8c8c;
    --light-background: #f2f2f2;
    --footer-background: var(
        --default-background
    ); // couleur de fond des footers sur les pages CGU, CGV, onboarding, startup notification, etc.

    --track-color-cff: #2d327d; // couleur des quais
    --track-color-cff-text: white; // couleur du texte des quais
    --leg-default-line-color: #cccccc; // couleur de la ligne dans thermo de leg si on n'a pas la couleur (ex. trajet CFF dans app TPC)
    --line-separator: #f0f0fa; // séparateurs entre les étapes d'un itinéraire et couleurs sous les trains dans le détail d'un départ
    --cgu-link: #2196f3; // TPC : paiement par sms
    --disabled-color: #bbbbbb; // texte champs grisés

    --inactiveColor: #ffffff;
    --line-inpast: #78787847;
    --unavailable-btn: gray;

    --disabled-text-color: #818181;

    --disabled-endpoint: #7f7f7f; // texte du endpoint arrivée si pas rempli, texte des endpoints sur le détail d'un départ/itinériare
    --input-placeholder: #bebebe; // textes dans les placeholders par exemple à l'ajout d'un voyageur
    --grid-button-color: #ffffff;
    --user-marker-pin-after: #e6e6e6; // unused ???
    --map-favorites-color: #ffffff; // unused ???

    --feedback-subtitle-color: #646464;
    --tpc-eiv-line-color: #b8d15f;

    // Tech / Ionic
    //--ion-toolbar-color: #000;
    //--ion-background-color: var(--default-background);
    //--ion-background-color-rgb: 255, 255, 255;
    //--ion-toolbar-background: var(--default-background);

    // Fonts
    --text-color: #000000;
    --text-color-light: #333333;
    --ion-text-color: var(--text-color);
    --ion-text-color-rgb: 0, 0, 0;

    // Forms
    --form-background: #ffffff; // tpc only : couleur de fond des formulaires de connexion/inscription/modifications compte
    --form-border: #dfdfe4; // ligne grise séparation des conditions d'utilisation, bordures des inputs autour des ajouts de voyageurs
    --ion-item-separator: #f0f1f8; // lignes utilisées pour séparer les listes d'objets (ion-items) et pour les séparations
    --disabled-toggle: #dddddd; // partout dans l'app : couleur grise pour le fond des toggles quand non cochés (choix 1ère classe dans les billets par exemple)
    --textbox-background-color: #f5f5f5; // fond des tuiles de favoris, fond des inputs de voyageurs toggle pickers (onboarding), fond des disruptionMessages d'erreur, fond du message "connexion", ...

    // Clickable "button" links
    --button-link-color: #000; // couleur des liens soulignés, genre "supprimer mon compte" dans mon profil

    // Departures
    --departure-background: var(
        --light-background
    ); // home : arrière plan des départs dans la liste + arrière-plan du message "pas de départs proches
    --departure-color: rgba(255, 255, 255, 0.8); // couleur de fond des endpoints départ/arrivée en haut de la home

    // Collapse arrows
    --collapse-arrow: var(--ion-color-primary); // couleur flèche quand un accordéon est plié
    --uncollapse-arrow: #ffffff; // couleur flèche quand un accordéon est déplié

    // Favorites
    --favorites-background: #989898; // couleur de fond derrière les tuiles
    --favorites-tile-edition-mode: var(--disabled-toggle); // couleur des tuiles non remplies en MODE EDITION

    .dark {
        // Backgrounds
        --default-background: #121212;
        --default-background-rgb: 18, 18, 18;
        --dark-background: var(--ion-color-step-50);
        --medium-background: var(--ion-color-step-150);
        --light-background: var(--ion-color-step-250);
        --footer-background: var(--ion-color-step-100);

        --line-separator: var(--departure-background);

        --disabled-text-color: #cccccc;

        // Tech / Ionic
        --ion-toolbar-color: #fff;
        --ion-background-color: var(--default-background);
        --ion-background-color-rgb: 18, 18, 18;
        --ion-toolbar-background: var(--default-background);

        // Fonts
        --text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;
        --text-color-light: #333333;
        --ion-text-color: var(--text-color);

        // Forms
        --form-background: #505050; // tpc only : couleur de fond des formulaires de connexion/inscription/modifications compte
        --form-border: #3c3c3c; // ligne grise séparation des conditions d'utilisation, bordures des inputs autour des ajouts de voyageurs
        --ion-item-separator: #3a3a3a; // lignes utilisées pour séparer les listes d'objets (ion-items) et pour les séparations
        --disabled-toggle: #565353; // partout dans l'app : couleur grise pour le fond des toggles quand non cochés (choix 1ère classe dans les billets par exemple)
        --textbox-background-color: var(
            --light-background
        ); // fond des tuiles de favoris, fond des inputs de voyageurs toggle pickers (onboarding), fond des disruptionMessages d'erreur, fond du message "connexion", ...

        // Clickable "button" links
        --button-link-color: #ffffff; // couleur des liens soulignés, genre "supprimer mon compte" dans mon profil

        // Departures
        --departure-background: var(
            --medium-background
        ); // home : arrière plan des départs dans la liste + arrière-plan du message "pas de départs proches
        --departure-color: rgba(0, 0, 0, 0.6); // couleur de fond des endpoints départ/arrivée en haut de la home

        // Collapse arrows
        --collapse-arrow: var(--ion-color-primary); // couleur flèche quand un accordéon est plié
        --uncollapse-arrow: #ffffff; // couleur flèche quand un accordéon est déplié

        // Favorites
        --favorites-background: var(--ion-color-step-500); // couleur de fond derrière les tuiles
        --favorites-tile-edition-mode: var(--ion-color-step-350); // couleur des tuiles non remplies en MODE EDITION

        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;
    }

    --ion-color-swisspass: #c51416; //
    --ion-color-swisspass-rgb: 197, 20, 22;
    --ion-color-swisspass-contrast: #ffffff;
    --ion-color-swisspass-contrast-rgb: 255, 255, 255;
    --ion-color-swisspass-shade: #ad1213;
    --ion-color-swisspass-tint: #cb2c2d;

    .ion-color-swisspass {
        --ion-color-base: var(--ion-color-swisspass);
        --ion-color-base-rgb: var(--ion-color-swisspass-rgb);
        --ion-color-contrast: var(--ion-color-swisspass-contrast);
        --ion-color-contrast-rgb: var(--ion-color-swisspass-contrast-rgb);
        --ion-color-shade: var(--ion-color-swisspass-shade);
        --ion-color-tint: var(--ion-color-swisspass-tint);
    }
}
