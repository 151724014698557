$baseWidth: 8.33333%;

@mixin custom-column-width($some-width) {
    -webkit-flex: 0 0 $baseWidth * $some-width !important;
    -ms-flex: 0 0 $baseWidth * $some-width !important;
    flex: 0 0 $baseWidth * $some-width !important;
    width: $baseWidth * $some-width !important;
    max-width: $baseWidth * $some-width !important;
}

@function number($value) {
    @if type-of($value) == 'number' {
        @return $value;
    }

    $result: 0;
    $digits: 0;
    $minus: str-slice($value, 1, 1) == '-';
    $numbers: (
        '0': 0,
        '1': 1,
        '2': 2,
        '3': 3,
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
        '8': 8,
        '9': 9,
    );

    @for $i from if($minus, 2, 1) through str-length($value) {
        $character: str-slice($value, $i, $i);

        @if not(index(map-keys($numbers), $character) or $character == '.') {
            @return length(if($minus, -$result, $result), str-slice($value, $i));
        }

        @if $character == '.' {
            $digits: 1;
        } @else if $digits == 0 {
            $result: $result * 10 + map-get($numbers, $character);
        } @else {
            $digits: $digits * 10;
            $result: $result + calc(map-get($numbers, $character) / $digits);
        }
    }

    @return if($minus, -$result, $result);
}

$rootRatio: 0;
$childRatio: 0;

@while $rootRatio < 12 {
    @while $childRatio < 10 {
        @if ($childRatio != 0) {
            .col-#{$rootRatio}-#{$childRatio} {
                @include custom-column-width(number('#{$rootRatio}.#{$childRatio}'));
            }
        }
        $childRatio: ($childRatio + 1);
    }
    $childRatio: 0;
    $rootRatio: ($rootRatio + 1);
}
