@forward 'customDevice.scss';

$smallWidth: 374px;

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@import 'shadowDomTricks.scss';
@import 'customDevice.scss';
@import 'customAccordion.scss';
@import 'customColumns.scss';
@import 'commonColors.scss';
@import 'custom-ionic.scss';

/** Ionic CSS Variables **/
:root {
    // this variable name is used in libs/boldor/common/src/lib/components/infinite-scroll-list/infinite-scroll-list.component.ts
    --journey-list-item-height: 2.5rem;
    --line-path-width: 0.6rem; // must pair value to be centered correctly with the actual centering system
    --bullet-thermo-size: 1.3rem;

    // This var is used to get the notch height (notch = sage-area-inset-top)
    --notch: env(safe-area-inset-top);

    .even {
        background-color: var(--default-background);
        --background: var(--default-background);
    }

    .odd {
        background-color: var(--default-background);
        --background: var(--default-background);
    }

    .z1 {
        z-index: 1;
    }

    --line-separator-width: 0.1rem;

    /** Line icon **/
    --separation-path-stroke: 0.2rem;
    --line-icon-height: 1.5rem;
    --line-icon-width: 1.5rem;
    --separation-width: 0.6rem;
    --line-icon-radius: 0.25rem;
    --request-size: 9rem;
    --side-margin: 7vw;

    app-map,
    mgl-map {
        .icon-track-number {
            font-size: 0.8rem;
        }

        --line-icon-height: 1.65rem;
        --line-icon-width: 1.65rem;
    }

    ion-header ion-toolbar:first-child {
        padding-top: env(safe-area-inset-top);
    }

    .android {
        .offset-status-bar {
            padding-top: 0;
        }
    }

    .ios {
        .offset-status-bar {
            padding-top: calc(env(safe-area-inset-top) - 1.1rem);
            @media screen and (max-width: $smallWidth) {
                padding-top: env(safe-area-inset-top);
            }
        }
    }

    .dark {
        background: var(--default-background);
    }

    --menu-icon-font-size: 1.5rem;

    --ion-grid-padding: 0.7rem;
}

html {
    /*
     * For more information on dynamic font scaling, visit the documentation:
     * https://ionicframework.com/docs/layout/dynamic-font-scaling
     */
    --ion-dynamic-font: var(--ion-default-dynamic-font);
}
