//TODO ionic 6: remove this and use ion-accordion

ion-list.accordion-list {
    padding-top: 0;
    padding-bottom: 0;
}

ion-item-group {
    ion-item-divider.accordion-header {
        background-color: transparent;
        border-bottom: 1px solid var(--ion-item-separator);
        color: var(--text-color);
        font-weight: normal;
        //font-size: 1.8rem;
        margin: 0;
        //padding: 1rem 1.5rem 1rem 2.4rem;
        --inner-padding-end: 0;

        .accordion-item {
            padding-left: 0.5rem;
            display: flex;
            flex-grow: 1;
        }

        .ticket-arrow {
            flex-shrink: 0;
            //font-size: 3.2rem;
            color: var(--text-color);

            &.rotated {
                transform: rotate(-90deg);
            }
        }

        ion-icon {
            color: var(--text-color);
        }

        &.active {
            background: var(--background-activated, var(--ion-color-primary));
            border-bottom: 0.1rem solid var(--background-activated, var(--ion-color-primary));

            ion-icon {
                color: var(--color-activated, var(--ion-color-primary-contrast));
            }

            .accordion-item {
                color: #ffffff;
            }
        }

        ion-icon {
            //font-size: 2.3rem !important;
            display: flex;
            flex-shrink: 0;
        }
    }
}
