:root {
    // Tricks we need to do for device to have the same behavior as chrome.

    .ios,
    .md {
        ion-button {
            --box-shadow: none;
        }
    }
}
