.dark {
    .marker-cluster-small,
    .marker-cluster-medium,
    .marker-cluster-large {
        background: var(--track-color-cff);
    }

    // Alerts
    ion-spinner {
        --color: var(--text-color);
    }

    // delay color
    .delay {
        color: #ffcc01;
    }
}
